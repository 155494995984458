<template>
  <div>
    <h2 class="contact-title">
      {{ section.title }}
    </h2>
    <div class="contact-section">
      <section class="contact-section-lcolumn">
        <div v-for="block in getLeftColumn" :key="`${section.id}block${block.id}`">
          <p class="label">
            {{ block.label }}
          </p>
          <a class="link-text ckEditor-html" v-html="block.content"></a>
        </div>
      </section>
      <section class="contact-section-rcolumn">
        <div v-for="block in getRightColumn" :key="`${section.id}block${block.id}`">
          <p class="label">
            {{ block.label }}
          </p>
          <a class="link-text ckEditor-html" v-html="block.content"></a>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactSection',

  props: {
    section: {
      type: Object,
      required: true
    }
  },

  computed: {
    getLeftColumn () {
      return this.section.contacts_blocks.filter(block => !block.position).sort((a, b) => {
        return b.order - a.order
      })
    },

    getRightColumn () {
      return this.section.contacts_blocks.filter(block => block.position).sort((a, b) => {
        return b.order - a.order
      })
    }
  }
}
</script>

<style scoped lang="scss">
.ckEditor-html {
  ::v-deep {
    * {
      margin: revert;
      padding: revert;
      list-style-type: revert;
      font-size: revert;
      font-weight: revert;
    }
  }
}
  .contact-title{
    font-size:1.25rem;
    margin-top:1.85rem;
    margin-bottom:0;
  }

  .contact-section{
    width:100%;
    display:flex;
    justify-content:space-between;
    flex-wrap:wrap;
    padding-bottom:2rem;
  }

  .contact-section-lcolumn,
  .contact-section-rcolumn
  {
    max-width:40rem;
    width:100%;
  }

  ::v-deep .label{
    font-size:.95rem;
    color:rgba(23, 23, 23, .8);
    margin-bottom: .5rem;
    margin-top: 2.2rem;
  }

  .link-text, .link-text ::v-deep p{
    font-size:.95rem;
    color:#246CB7;
    text-decoration:none;
    margin-top: 1rem;
    display:block;
  }

  .link-text ::v-deep strong{
    color:rgba(23, 23, 23, .8);
  }

  .link-text ::v-deep a{
    color:#246CB7;
    font-size:.95rem;
  }
  @media screen and (max-width: 420px) {
    .link-text{
      max-width: 94%;
    }
  }
</style>
