<template>
    <div>
      <bread-crumb :navigationArray="navigationLink"/>
      <!-- <h1 class="title-feedback">{{ $store.getters.GET_LANG ? 'Контакты' : 'Contacts' }}</h1> -->

      <div class="white-container">
        <p class="title_categories">{{ $store.getters.GET_LANG ? 'Контакты' : 'Contacts' }}</p>
        <tricolor-line />
        <!-- <p class="hotline-text-container">{{ $store.getters.GET_LANG ? 'Вы можете обратиться на постоянно действующую Горячую линию Общественной Палаты РФ по любым вопросам или горячие линии, открытые по определенной теме.' : 'You can contact the permanent hotline of the Public Chamber of the Russian Federation for any questions or hotlines open on a specific topic.' }}</p> -->
        <p class="hotline-text-container ckEditor-html" v-html="getFeedbackHotline.feedback"></p>
        <section class="hotline-banner-wrapper">
          <h2 class="hotline-title">
            {{ $store.getters.GET_LANG ? 'Прямая линия Общественной палаты Российской Федерации' : 'Direct line of the Civic Chamber of the Russian Federation' }}
          </h2>
          <a :href="`tel:${getFeedbackHotline.phone}`" class="hot-line-phone">
            {{getFeedbackHotline.phone}}
          </a>
          <p class="hotline-paragraph" v-html="getFeedbackHotline.graphic">
            <!-- {{ $store.getters.GET_LANG ? 'Звонок из любого региона России бесплатный' : 'Calls from any region of Russia are free' }} -->
          </p>
          <!-- <div class="hotline-wrapper-date">
            <img src="../../assets/icon/clock.svg" alt="icons"><p>{{ $store.getters.GET_LANG ? 'пн-чт с 9 до 18, пт - сб 9:00 до 16:45 ( MSK )' : 'Mon-Thu from 9 am to 6 pm, Fri - Sat 9 am to 4:45 pm (MSK)' }}</p>
          </div> -->
          <div class="hot-line-figure" style="background: url('/img/figure1.b7b5f0ae.png') left top no-repeat;"></div>
        </section>
      </div>

      <div class="tiles-contact-wrapper">
        <div class="tile-one-wrapper">
          <h2 class="tile-title">
            {{ $store.getters.GET_LANG ? 'Интернет-приемная Общественной палаты РФ' : 'Internet reception of the Public Chamber of the Russian Federation' }}
          </h2>
          <div v-for="(items, i) in InformationService" :key="'InformationService'+i">
            <p class="tile-label">{{items.title}}</p>
            <a v-for="(value, i) in items.data" :key="'link_'+i" :href="`${(value).substring(0, 1) === 'h' ? value : ('tel:' + value)}`" class="tile-link">
              {{value}}<br>
            </a>
            <p class="tile-label-thin">
            </p>
          </div>
        </div>

        <div class="tile-one-wrapper">
          <h2 class="tile-title">
            {{ $store.getters.GET_LANG ? 'Телефон для справок' : 'Telephone for information on admission and consideration of citizens' }}
          </h2>
          <p class="tile-label">
            {{ $store.getters.GET_LANG ? 'Телефон' : 'Phone' }}
          </p>
          <a :href="`tel:${item}`" class="tile-link" v-for="(item,i) in getCertificateAdmission.phones" :key="'CertificateAdmission'+i">
            {{item}}
          </a>
          <p class="tile-label-thin">
          </p>
          <p class="tile-label">
            {{ $store.getters.GET_LANG ? getCertificateAdmission.contacts[0].position : getCertificateAdmission.contacts_eng[0].position }}
          </p>
          <a class="tile-link">
            {{ $store.getters.GET_LANG ? getCertificateAdmission.contacts[0].full_name : getCertificateAdmission.contacts_eng[0].full_name }}
          </a>
        </div>

        <div class="tile-one-wrapper">
          <h2 class="tile-title">
            <a>
             {{ $store.getters.GET_LANG ? 'Пресс-служба Общественной палаты РФ' : 'Press Service of the Public chamber RF' }}
            </a>
          </h2>
          <p class="tile-label">
            {{ $store.getters.GET_LANG ? 'Телефон/факс' : 'Phone/fax' }}
          </p>
          <a :href="`tel:${getHotLine.phone}`" class="tile-link">
            {{getHotLine.phone}}
          </a>
          <p class="tile-label-thin"></p>
          <!-- <div class="tile-mode-wrapper"> -->
            <p class="tile-label">
              {{ $store.getters.GET_LANG ? 'Электронная почта' : 'Email' }}
            </p>
            <a :href="`mailto:${getHotLine.email}`" class="tile-link">
              {{getHotLine.email}}
            </a>
            <!-- <p class="work-day" v-for="(item,i) in  HotLine" :key="'time'+i">
              <strong>{{item.title}}</strong>
              <strong>{{item.time}}</strong>
            </p> -->
          <!-- </div> -->
        </div>

      </div>
      <div class="feedback-map-wrapper">
        <section class="map-left-wrapper">
          <h2 class="tile-title">
            {{ $store.getters.GET_LANG ? 'Общественная палата Российской Федерации' : 'Public Chamber of the Russian Federation' }}
          </h2>
          <br>
             <p class="tile-label">
            {{ $store.getters.GET_LANG ? 'Адрес' : 'Address' }}
          </p>
          <a class="tile-link">
            {{ $store.getters.GET_LANG ? getInternetReception.address : getInternetReception.address_eng}}
          </a>
          <br>
          <br>
          <p class="tile-label">
            {{ $store.getters.GET_LANG ? 'Электронная почта для служебной корреспонденции' : 'E-mail for business correspondence' }}
          </p>
          <a :href="`mailto:${getInternetReception.email}`" class="tile-link">
            {{getInternetReception.email}}
          </a>
          <br>
          <br>
          <p class="tile-label">
            {{ $store.getters.GET_LANG ? 'Факс' : 'Fax' }}
          </p>
          <a href="tel: 8(495)132-59-96" class="tile-link">
             8 (495) 132-59-96
          </a>
             <br>
          <p class="tile-label">
            {{ $store.getters.GET_LANG ? 'Соц. сети' : 'Soc. networks' }}
          </p>
          <div class="social-wrapper">
            <a target="_blank" :href="item.link" v-for="item in $store.getters.getSocialNetwork" :key="item.id+'icon'">
              <img :src="item.icon" alt="">
            </a>
            <!-- <img src="../../assets/icon/Facebook.svg">
            <img src="../../assets/icon/vk.svg">
            <img src="../../assets/icon/Twitter.svg">
            <img src="../../assets/icon/telegram.svg">
            <img src="../../assets/icon/youtube.svg">
            <img src="../../assets/icon/rts.svg"> -->
          </div>
          <a href="https://eis.oprf.ru/treatments/send" class="feedback-appeals-button">{{ $store.getters.GET_LANG ? 'Подать обращение' : 'Submit an appeal' }}</a>
<!--          <a class="feedback-appeals-button" href="https://oprflk.dev.devfbit.ru">Подать обращение</a>-->
        </section>
        <section class="map-right-wrapper">
          <iframe class="map-frame" src="https://yandex.ru/map-widget/v1/?um=constructor%3Afce6d80c3fb8fbf4dac4c45193f7059396256ae3e2a0094c76f737cf143ca370&amp;source=constructor" frameborder="0"></iframe>
        </section>
      </div>
      <div class="white-container container_margin">
        <contact-section v-for="(section, index) in getContacts" v-show="section.title !== 'Пресс-служба Общественной палаты РФ'"
                        :key="`contactSection${index}`"
                        :section="section"/>
      </div>
    </div>
</template>

<script>
import BreadCrumb from '@/components/BreadCrumb'
import TricolorLine from '@/components/TricolorLine'
import ContactSection from '@/components/contact/ContactSection'
export default {
  name: 'MainContact',
  components: {
    ContactSection,
    BreadCrumb,
    TricolorLine
  },
  data () {
    return {
      currentPage: 1,
      contactsPosition: '',
      contactsFull_name: '',
      informationService: [
        {
          title: '',
          data: []
        }
      ]
    }
  },
  mounted () {
    this.$store.dispatch('setHotLine')
    this.$store.dispatch('setPhoneCertificateAdmission')
    this.$store.dispatch('setInformationService')
    this.$store.dispatch('setInternetReception')
    this.$store.dispatch('setSocialNetwork')
    this.getData()
    this.$store.dispatch('setFeedbackHotline')
    document.title = 'Контакты'
  },
  watch: {
    '$store.getters.GET_LANG' () {
      this.getData()
    }
  },
  computed: {
    getFeedbackHotline () {
      return this.$store.getters.getFeedbackHotline
    },
    getContacts () {
      return this.$store.getters.getContacts.filter(contact => contact.title !== (this.$store.getters.GET_LANG ? 'Пресс-служба Общественной Палаты' : 'Press service of the Public Chamber'))
    },
    getHotLine () {
      return this.$store.getters.getHotLine
    },
    HotLine () {
      return this.$store.getters.GET_LANG ? this.getHotLine.work_time : this.getHotLine.work_time_eng
    },
    getCertificateAdmission () {
      return this.$store.getters.getPhoneCertificateAdmission
    },
    getInformationService () {
      return this.$store.getters.getInformationService
    },
    InformationService () {
      return this.$store.getters.GET_LANG ? this.getInformationService.blocs : this.getInformationService.blocs_eng
    },
    getInternetReception () {
      return this.$store.getters.getInternetReception
    },
    navigationLink () {
      return [
        {
          title: this.$store.getters.GET_LANG ? 'Главная' : 'Home',
          path: '/'
        },
        {
          title: this.$store.getters.GET_LANG ? 'Контакты' : 'Contacts'
        }
      ]
    }
  },
  methods: {
    getData () {
      this.$store.dispatch('getContacts', {
        headers: {
          Localization: this.$store.getters.GET_LANG ? 'ru' : 'eng'
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  // .title-feedback{
  //   max-width:85.37rem;
  //   width:100%;
  //   margin:3rem auto 2.5rem auto;
  //   font-size: 1.37rem;
  //   letter-spacing:.5px;
  // }
  .tiles-contact-wrapper{
    max-width:85.37rem;
    width:100%;
    margin:0 auto;
    display:flex;
    flex-wrap:wrap;
    justify-content: space-between;
  }
  .tile-one-wrapper{
    max-width:26.6rem;
    width:100%;
    margin-bottom:4rem;
    padding:2rem;
    background-color:#fff;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 15px 25px rgba(189, 205, 221, 0.35);
    border-radius: 8px;
  }
  .tile-title{
    font-size:1rem;
    font-weight:600;
    color:#171717;
    margin-bottom: 2rem;
  }
  .tile-label{
    font-size:.85rem;
    color: rgba(23, 23, 23, 0.8);
    font-style: normal;
    font-weight: normal;
    margin:.5rem 0;
  }
  .tile-link{
    color: #246CB7;
    text-decoration:none;
    font-size:1rem;
  }
  .tile-label-thin{
    font-size:.75rem;
    color: rgba(23, 23, 23, 0.8);
    font-style: normal;
    font-weight: lighter;
    margin:.5rem 0 2rem 0;
  }
  .work-day{
    display:flex;
    justify-content: space-between;
    max-width:17rem;
    width:100%;
  }
  .work-day>strong{
    font-weight: normal;
  }
  .feedback-map-wrapper{
    max-width:85.37rem;
    width:100%;
    margin:0 auto;
    display:flex;
    justify-content: space-between;
    flex-wrap:wrap;
    padding:2rem;
    box-sizing:border-box;
    background-color:#fff;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 15px 25px rgba(189, 205, 221, 0.35);
    border-radius: 8px;
  }
  .map-left-wrapper{
    max-width:17rem;
    width:100%;
  }
  .social-wrapper{
    display:flex;
    flex-wrap: wrap;
    // width: 10rem;
    a{ margin-right: 1rem; margin-bottom: .5rem;}
    img {
      width: 2rem;
      // margin-right: 1rem;
    }
  }
  .feedback-appeals-button{
    background-color:#EB494E;
    max-width:16rem;
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    color:#fff;
    text-decoration:none;
    padding:.5rem 0;
    margin-top:2rem;
    border-radius: 3px;
  }
  .map-right-wrapper{
    max-width:60rem;
    width:100%;
    text-align: right;
  }
  .map-right-wrapper>img{
    width:100%;
  }
  .map-frame{
    max-width:initial;
    width:100%;
    min-height:22rem;
    height:100%;
  }

  @media (max-width:768px){
    ::v-deep .feedback-map-wrapper{
      flex-direction: column-reverse;
      max-width:40rem;
    }
    ::v-deep .map-left-wrapper{
      max-width:50rem;
      margin:0 auto;
    }
    ::v-deep .tile-title{
      margin-top:2rem;
    }
    ::v-deep .feedback-appeals-button{
      margin:2rem auto 0 auto;
    }
    ::v-deep .tile-one-wrapper{
      max-width:40rem;
      margin:1rem auto;
    }
    // ::v-deep .title-feedback{
    //   width: 92.4%;
    //   max-width:40rem;
    //   margin: 2rem auto 1.5rem auto;
    //   /* margin-left:auto;
    //   margin-right:auto; */
    // }
    ::v-deep .work-day{
      font-size:300%;
    }
    ::v-deep .feedback-appeals-button{
      font-size:300%;
    }
  }
</style>
<style scoped lang="scss">
  .ckEditor-html {
    ::v-deep {
      * {
        margin: revert;
        padding: revert;
        list-style-type: revert;
        font-size: revert;
        font-weight: revert;
      }
    }
  }
  .white-container{
    max-width: 85.37rem;
    width: 100%;
    margin: 0 auto 4rem auto;
  }
  .container_margin{
    padding-top: 2.75rem;
    margin: 4rem auto 0 auto;
    &:first-child h2{
      margin-top: 0;
    }
  }

  .title_categories{
    margin-bottom: 1.25rem;
  }

  .strong-text{
    color:black;
    font-weight:bold;
  }

  .contact-section{
    width:100%;
    display:flex;
    justify-content:space-between;
    flex-wrap:wrap;
    padding-bottom:2rem;
  }

  .contact-section-lcolumn,
  .contact-section-rcolumn
  {
    max-width:40rem;
    width:100%;
  }

  .contact-title{
    font-size:1.25rem;
    margin-top:1.85rem;
    margin-bottom:0;
  }

  .label{
    font-size:.95rem;
    color:rgba(23, 23, 23, .8);
    margin-botom:.5rem;
    margin-top:2.2rem;
  }

  .link-text{
    color:#246CB7;
    text-decoration:none;
    margin-top: 1rem;
    display:block;
  }

  .hotline-text-container{
    margin:2rem 0;
    color:rgba(23, 23, 23, .8);
    font-size:1.1rem;
  }

  .hotline-banner-wrapper{
    padding: 1.87rem;
    background: linear-gradient(to right top, rgba(77, 105, 135, 0.2), rgba(255, 255, 255, 0.2));
    box-shadow: 0 15px 25px rgba(189,205,221,.35);
    border-radius: 8px;
    border:1px solid #EEF4FA;
    position: relative;
    overflow: hidden;
  }

  .hotline-title{
    font-size:1.25rem;
    margin-top:0;
    margin-bottom:0;
  }

  .hot-line-phone {
    display: inline-block;
    font-weight: bold;
    font-size: 1.43rem;
    line-height: 130%;
    letter-spacing: 2px;
    color: #fff;
    background: #D32E2E;
    border-radius: 8px;
    padding: 0.63rem 1.675rem;
    margin-top:1rem;
    margin-bottom: 1.3rem;
  }

  .hotline-paragraph{
    font-size:1.05rem;
    margin-bottom:1rem;
  }

  .hotline-wrapper-date{
    display: flex;
    align-items: center;
    p{
      font-size: 1rem;
    }
  }

  .hotline-wrapper-date img{
    margin-right: 1rem;
  }

  .hot-line-figure{
    position: absolute;
    right: 0;
    top: 0;
    width: 25rem;
    height: 25rem;
    opacity: .2;
    border-radius: 70% 0 0 60%;
    background-size: 150% !important;
    background-position: -47px -17px !important;
  }

  .tile-wrapper{
    display:flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .tile{
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    max-width:40rem;
    width:100%;
    box-sizing: border-box;
    min-height:18.125rem;
    padding:2.5rem 3rem;
    border: 1px solid #EEF4FA;
    box-sizing: border-box;
    box-shadow: 0px 15px 25px rgba(189, 205, 221, 0.35);
    border-radius: 8px;
  }

  .tile-title-wrapper{
    display:flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 1.5rem;
  }

  .tile-title-wrapper h3{
    font-size:1.25rem;
    margin-top:0;
    margin-bottom:0;
    margin-left:1rem;
  }

  .tile-description{
    font-size:1.05rem;
    margin-bottom:1rem;
  }

  .tile-button{
    display:block;
    text-align: center;
    width:22rem;
    color:#fff;
    font-size:1rem;
    background-color:#246CB7;
    padding:.7rem .5rem;
    border-radius:3px;
  }

  @media (max-width:768px){
    .link-text{
      font-size:2vw !important;
    }

    .tile{
      max-width:768px;
      margin-bottom:2rem;
    }
    .white-container{
      max-width: 40rem;
    }
  }
  @media (max-width: 420px){
    .tile-button{
      width: 100%;
    }
  }
</style>
